<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Orphaned Items</h3>

	<p>An orphaned item is an item that does not have an “isChildOf” association tying it to a parent item in the framework’s tree structure. Orphaned items most commonly occur when using the batch update tools where new copies of items are created and the old versions of the items are not removed.</p>

    <p><Strong>Finding Orphaned Items:</Strong>
        <ul>
            <li>Toggle to the table view of the framework. </li>
            <img alt="Switch table view image" srcset="/docimages/switch_table_view.png 1.6x " class="k-help-img">
            <img alt="Hide filters image" srcset="/docimages/hide_filters.png 1.8x " class="k-help-img float-right">
            <li  class="k-no-clear">If <strong># Parents</strong> is not a listed field on the right side of the table, click <img alt="Show filters image" srcset="/docimages/show_filters.png 3.1x " class="k-help-img"> in the top left corner.
                
                <ul>
                    <li>Under <strong>Show fields:</strong> check the # Parents** box. </li>
                    <li>The <strong># Parents</strong> column field should appear toward the right side of the table. </li>
                </ul>
            </li>
            <li>Click the <strong># Parents</strong> column heading one time and an upwards pointing arrow will appear to signify that the items are now ascending from items with the least number of parents to the most number of parents. </li>
           
            <li>All items should have a parent item and should have at least a “1” listed in the <strong># Parents</strong> column.
                <ul>
                    <li>Orphaned items will appear with no number or a zero listed in the <strong># Parents</strong> column.</li>
                    <img alt="Orphaned items image" srcset="/docimages/orphaned_items.png 2x " class="k-help-img block">
                </ul>
            </li>
            <li>These orphaned items may have a caution triangle next to the “Full Statement” field. </li>
        </ul>
    </p>

    <p>If the item should exist in the framework, determine if there is another version of the item that is not an orphan.
        <ul>
            <li>Copy the full statement of the orphaned item.</li>
            <li>Click the lower search bar in the top-right corner, paste the text of the orphaned item, and search</li>
            <li>If the item exists, it will appear in the table. </li>
            <li>If no item appears, then a new item should be created before Removing the Orphaned Item:
                <ul>
                    <li>Copy the full text statement of the Orphaned Item</li>
                    <li>Following the Adding new items user guide to add the new item to the proper parent item.</li>
                </ul>
            </li>
            <li>Follow the below steps for Removing an Orphaned Item </li>
        </ul>
    </p>

    <img alt="More options orphaned image" srcset="/docimages/more_options_orphaned.png 1.6x " class="k-help-img float-right"></li>
    <p class="k-no-clear"><strong>Removing an Orphaned Item</strong>
        <ul>
            <li>Click <img alt="Return to tree view button example image" srcset="/docimages/return_tree.png 3.1x " class="k-help-img"></li>
            <li>Enable framework editing and then click on the  icon of the document-level “node” of the tree to see the document’s “card”:</li>
            <li>Click <strong>EDIT FRAMEWORK</strong> > More options > Clean framework JSON…</li>
            <li>The Clean Framework JSON window will appear.</li>
            <li>Leave the top two items checked and scroll to the bottom</li>
            <li>Select the “Remove ‘orphans’” box </li>
            <li>Click <strong>SIMULATE FILE SIZE REDUCTION</strong></li>
        </ul>
    </p>
    <br>
    <br>





</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>